import { ComponentCompanyColor, ComponentModulesTabs, Vendor } from 'etc/strapi-types'
import { Grid, Typography } from 'antd'
import Tabs, { TabPane } from 'rc-tabs'
import Spacer from 'components/spacer'
import TabBar from 'components/tab-bar'
import RichTextModule from './rich-text-module'
import styles from './tab-module.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	module: ComponentModulesTabs
	vendor?: Vendor
	colors?: ComponentCompanyColor
}

const TabsModule = ({ module, vendor, colors }: Props) => {
	const { title, subtitle, text, items, margins } = module

	const screen = useBreakpoint()

	const accentColor = colors?.accent ?? vendor?.color?.accent

	return (
		<div className={styles.container}>
			{margins?.top && <Spacer size={screen.md ? 78 : 48} />}
			{subtitle && (
				<Text className={`regular-${screen.md ? 20 : 16}`} style={{ color: accentColor }}>
					{subtitle.toUpperCase()}
				</Text>
			)}
			{title && (
				<div className={styles.title}>
					<Text className={`semibold-${screen.md ? 38 : 30} grey-9`}>{title}</Text>
				</div>
			)}
			{text && (
				<div className={styles.text}>
					<Text className='regular-16 grey-8'>{text}</Text>
				</div>
			)}
			<Tabs
				renderTabBar={props => {
					// TODO: HOTFIX, needs to be investigated
					const { activeKey, panes, onTabClick } = props as any
					return (
						<div className={styles.tabs}>
							<TabBar
								activeKey={activeKey}
								panes={panes}
								onTabClick={onTabClick}
								color={accentColor}
							/>
						</div>
					)
				}}
			>
				{items?.map(
					(item, index) =>
						item && (
							<TabPane key={index} tab={item.title}>
								<RichTextModule module={{ id: '', content: item.content }} />
							</TabPane>
						)
				)}
			</Tabs>
			{margins?.bottom && <Spacer size={screen.md ? 78 : 48} />}
		</div>
	)
}

export default TabsModule
