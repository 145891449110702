import { useEffect, useState } from 'react'
import { UploadFile } from 'etc/strapi-types'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styles from './lightbox.module.less'

interface Props {
	images: UploadFile[]
	visible: boolean
	hide: () => void
}

const Lightbox = ({ images, visible, hide }: Props) => {
	const [activeIndex, setActiveIndex] = useState(0)

	useEffect(() => {
		if (visible) {
			setActiveIndex(0)
		}

		document.body.style.overflowY = visible ? 'hidden' : 'scroll'

		function handleKeyDown({ keyCode }: KeyboardEvent) {
			switch (keyCode) {
				case 27:
					if (visible) hide()
					break
				case 37:
					setActiveIndex(index => (index > 0 ? index - 1 : index))
					break
				case 39:
					setActiveIndex(index => (index < images.length - 1 ? index + 1 : index))
					break
			}
		}

		document.addEventListener('keydown', handleKeyDown)

		return () => document.removeEventListener('keydown', handleKeyDown)
	}, [visible])

	return !visible ? null : (
		<div onClick={hide} className={styles.container}>
			<div className={styles.content} onClick={e => e.stopPropagation()}>
				<div className={styles.imageContainer}>
					<img src={images[activeIndex].url} className={styles.image} />
					<LeftOutlined
						onClick={() => setActiveIndex(index => (index > 0 ? index - 1 : index))}
						className={styles.arrowLeft}
						style={{ opacity: activeIndex === 0 ? 0.4 : 1 }}
					/>
					<RightOutlined
						onClick={() => setActiveIndex(index => (index < images.length - 1 ? index + 1 : index))}
						className={styles.arrowRight}
						style={{ opacity: activeIndex === images.length - 1 ? 0.4 : 1 }}
					/>
				</div>
				<div className={styles.slider}>
					<div>
						{images.map((image, index) => (
							<img
								key={index}
								src={image.url}
								onClick={() => setActiveIndex(index)}
								className={styles.thumb}
								style={index === activeIndex ? { filter: 'brightness(1)' } : undefined}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Lightbox
