import { ComponentCompanyColor, ComponentModulesVideo, Vendor } from 'etc/strapi-types'
import { getColors } from 'services/color'
import { Grid, Typography } from 'antd'
import Spacer from 'components/spacer'
import styles from './video-module.module.less'
import MediaEmbed from './media-embed'

const { Text } = Typography
const { useBreakpoint } = Grid

interface Props {
	module: ComponentModulesVideo
	vendor?: Vendor
	colors?: ComponentCompanyColor
}

const VideoModule = ({ module, vendor, colors }: Props) => {
	const { youtube, title, subtitle, text, margins } = module

	const { accent } = getColors(colors ?? vendor?.color ?? null, 'light')

	const screen = useBreakpoint()

	return (
		<div className={styles.container}>
			{margins?.top && <Spacer size={screen.md ? 78 : 48} />}
			<div className={styles.content}>
				{subtitle && (
					<Text className='semibold-16' style={{ color: accent }}>
						{subtitle}
					</Text>
				)}
				{title && (
					<div className={styles.title}>
						<Text className='semibold-30 grey-9'>{title}</Text>
					</div>
				)}
				{text && <Text className='regular-16 grey-8'>{text}</Text>}
			</div>
			<MediaEmbed url={youtube} />
			{margins?.bottom && <Spacer size={screen.md ? 78 : 48} />}
		</div>
	)
}

export default VideoModule
