import { useQuery } from '@apollo/client'
import { PRODUCT_SPOTLIGHT } from 'etc/queries'
import { Product } from 'etc/storefront-types'
import { ComponentCompanyColor, ComponentModulesProduct, Vendor } from 'etc/strapi-types'
import Spacer from 'components/spacer'
import FeaturedProduct from 'components/product/featured-product'
import { getColors } from 'services/color'

interface Props {
	module: ComponentModulesProduct
	vendor?: Vendor
	colors?: ComponentCompanyColor
}

const ProductModule = ({ module, vendor, colors }: Props) => {
	const { handle, margins } = module

	const { data } = useQuery<{ productByHandle: Product }>(PRODUCT_SPOTLIGHT, {
		variables: { handle }
	})

	const accent = vendor && getColors(colors ?? vendor?.color ?? null, 'light').accent

	return !data?.productByHandle ? null : (
		<div>
			{margins?.top && <Spacer size={78} />}
			<FeaturedProduct product={data.productByHandle} color={accent} />
			{margins?.bottom && <Spacer size={78} />}
		</div>
	)
}

export default ProductModule
