import Link from 'next/link'
import { Product } from 'etc/storefront-types'
import {
	formatPriceString,
	getComparedPrices,
	getProductUnitPrice,
	getRangePriceString
} from 'services/price'
import { Button, Col, Grid, Row, Typography } from 'antd'
import clip from 'text-clipper'
import Delivery from 'components/product/delivery'
import Spacer from 'components/spacer'
import styles from './featured-product.module.less'
import ZoomImage from './zoom-image'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	product: Product
	color?: string
}

const FeaturedProduct = ({ product, color }: Props) => {
	const { sale } = getComparedPrices(product.compareAtPriceRange, product.priceRange)
	const unitPrice = getProductUnitPrice(product)

	const screen = useBreakpoint()

	return (
		<Row justify='center' align='middle' gutter={32}>
			<Col xs={24} sm={9}>
				<div className={styles.image}>
					<ZoomImage src={product.images.edges[0].node.transformedSrc} />
				</div>
			</Col>
			<Col xs={24} sm={10}>
				{screen.xs && <Spacer size={16} />}
				<Link href={`/produkt/${product.handle}`}>
					<a>
						<Text className='semibold-30'>{product.title}</Text>
					</a>
				</Link>
				<div className={styles.priceContainer}>
					<Text className={`regular-20 ${sale && styles.sale}`}>
						{getRangePriceString(product.priceRange)}
					</Text>
					{sale && (
						<Text className={`regular-20 grey-8 ${styles.compare}`}>
							{formatPriceString(product.compareAtPriceRange.minVariantPrice.amount)}
						</Text>
					)}
					<Text className={`regular-12 grey-7 ${styles.tax}`}>inkl. MwSt. </Text>
					{unitPrice && <Text className='regular-12 grey-7'> ({unitPrice})</Text>}
					<Row>
						<Delivery metafields={product.metafields} />
					</Row>
				</div>
				<Text className='regular-16 grey-8'>
					<div
						dangerouslySetInnerHTML={{
							__html: clip(product.descriptionHtml, 400, { html: true, maxLines: 6 })
						}}
					/>
				</Text>
				<Row className={styles.button}>
					<Col span={12}>
						<Link href={`/produkt/${product.handle}`}>
							<Button type='primary' size='large' block color={color}>
								Jetzt ansehen
							</Button>
						</Link>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default FeaturedProduct
