import styles from './media-embed.module.less'

interface Props {
	url: string
}

const MediaEmbed = ({ url }: Props) => {
	const regex =
		/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gm
	const matches = regex.exec(url)
	const id = matches ? matches[1] : null

	return !id ? null : (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<iframe
					width='560'
					height='315'
					src={`https://www.youtube.com/embed/${id}`}
					title='YouTube video player'
					frameBorder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowFullScreen
				/>
			</div>
		</div>
	)
}

export default MediaEmbed
