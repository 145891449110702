import { Typography } from 'antd'
import { useEffect } from 'react'
import styles from './tab-bar.module.less'

const { Text } = Typography

interface Props {
	activeKey: string
	activeTitle?: string | null
	panes: Array<{ key: string; props: { tab: string } }>
	onTabClick: (key: string) => void
	color?: string
}

const TabBar = ({ activeKey, activeTitle, panes, onTabClick, color = '#1f6c04' }: Props) => {
	useEffect(() => {
		if (activeTitle) {
			const key = panes.find(pane => pane.props.tab === activeTitle)?.key
			if (key) onTabClick(key)
		}
	}, [activeTitle])

	return (
		<div className={styles.container}>
			{panes.map(pane => {
				const active = activeKey === pane.key
				return (
					<div
						key={pane.key}
						onClick={() => onTabClick(pane.key)}
						className={styles.item}
						style={{
							borderBottomColor: active ? color : 'transparent'
						}}
					>
						<Text
							className={active ? 'semibold-16' : 'regular-16 grey-8'}
							style={{
								color: active ? color : undefined,
								whiteSpace: 'nowrap'
							}}
						>
							{pane.props.tab}
						</Text>
					</div>
				)
			})}
		</div>
	)
}

export default TabBar
