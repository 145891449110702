import { useEffect, useRef, useState } from 'react'
import { ComponentCompanyColor, ComponentModulesNavigation, Vendor } from 'etc/strapi-types'
import { hexToRgb } from 'services/color'
import { Button, Grid, Typography } from 'antd'
import Spacer from 'components/spacer'
import styles from './navigation-module.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface Props {
	module: ComponentModulesNavigation
	vendor?: Vendor
	colors?: ComponentCompanyColor
}

const NavigationModule = ({ module, vendor, colors }: Props) => {
	const [containerHeight, setContainerHeight] = useState<number>(0)
	const [contentHeight, setContentHeight] = useState<number>(0)

	const containerRef = useRef<HTMLDivElement>(null)
	const contentRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (containerRef.current) {
			setContainerHeight(containerRef.current.clientHeight)
		}
		if (contentRef.current) {
			setContentHeight(contentRef.current.clientHeight)
		}
	}, [])

	const screen = useBreakpoint()

	const { title, text, image, link, variant, color, margins } = module

	const light = color?.scheme === 'dark' || color?.scheme === 'company'

	const background =
		(color?.scheme === 'dark'
			? '#262626'
			: color?.scheme === 'company'
			? colors?.background ?? vendor?.color?.background
			: '#f5f5f5') ?? '#f5f5f5'

	const backgroundRgb = hexToRgb(background ?? '#f5f5f5')

	return (
		<div>
			{margins?.top && <Spacer size={screen.md ? 78 : screen.sm ? 48 : 24} />}
			<a href={link?.href}>
				<div
					ref={containerRef}
					className={`${styles.container} ${
						variant === 'full' ? styles.full : variant === 'big' ? styles.big : styles.default
					}`}
					style={{ backgroundColor: background }}
				>
					<div className={styles.imageContainer}>
						{image && <img src={image.url} alt='Hintergrund' className={styles.image} />}
						{!screen.md && (
							<div
								className={styles.imageOverlay}
								style={{
									background: `linear-gradient(0deg, rgba(${backgroundRgb.r}, ${backgroundRgb.g}, ${backgroundRgb.b}, 1) 12px, rgba(${backgroundRgb.r}, ${backgroundRgb.g}, ${backgroundRgb.b}, 0) 27%)`
								}}
							/>
						)}
					</div>
					<div>
						{screen.md && (
							<Gradients
								colors={vendor?.color}
								scheme={color?.scheme ?? 'light'}
								containerHeight={containerHeight}
								contentHeight={contentHeight}
							/>
						)}
						<div ref={contentRef} className={styles.content}>
							{title && (
								<Text
									className={`semibold-${screen.xl ? 30 : 20}`}
									style={{ color: light ? '#fff' : '#262626' }}
								>
									{title}
								</Text>
							)}
							{text && (
								<div className={styles.text}>
									<Text
										className={`regular-${screen.lg ? 16 : 14}`}
										style={{ color: light ? '#e8e8e8' : '#595959' }}
									>
										{text}
									</Text>
								</div>
							)}
							{!screen.lg && (
								<div className={styles.button}>
									<Button type='primary' color={vendor?.color?.accent}>
										{link?.name}
									</Button>
								</div>
							)}
						</div>
						{screen.lg && (
							<div className={styles.link} style={{ backgroundColor: vendor?.color?.accent }}>
								<img src='/images/icons/arrow.svg' />
							</div>
						)}
					</div>
				</div>
			</a>
			{margins?.bottom && <Spacer size={screen.md ? 78 : screen.sm ? 48 : 24} />}
		</div>
	)
}

export default NavigationModule

interface GradientsProps {
	scheme: 'light' | 'grey' | 'dark' | 'company'
	colors?: ComponentCompanyColor
	containerHeight: number
	contentHeight: number
}

const Gradients = ({ scheme, colors, containerHeight, contentHeight }: GradientsProps) => {
	const color = scheme === 'dark' ? '#262626' : scheme !== 'company' ? '#f5f5f5' : null

	const rgb1 = hexToRgb(color || colors?.background || '#f5f5f5')
	const rgb2 = hexToRgb(color || colors?.backgroundShade || '#f5f5f5')

	const ratio = (contentHeight + 64) / (containerHeight > 0 ? containerHeight : 1)

	return (
		<div>
			<div
				className={styles.overlay}
				style={{
					background: `linear-gradient(60.5deg, rgba(${rgb1.r}, ${rgb1.g}, ${rgb1.b}, 1) 0%, rgba(${
						rgb1.r
					}, ${rgb1.g}, ${rgb1.b}, 0) ${ratio * 70}%)`
				}}
			/>
			<div
				className={styles.overlay}
				style={{
					background: `linear-gradient(0deg, rgba(${rgb1.r}, ${rgb1.g}, ${rgb1.b}, 1) 0%, rgba(${
						rgb1.r
					}, ${rgb1.g}, ${rgb1.b}, 0) ${ratio * 100}%)`
				}}
			/>
			{(scheme === 'light' || scheme === 'grey') && (
				<>
					<div
						className={styles.overlay}
						style={{
							background: `linear-gradient(60.5deg, rgba(${rgb1.r}, ${rgb1.g}, ${
								rgb1.b
							}, 1) 0%, rgba(${rgb1.r}, ${rgb1.g}, ${rgb1.b}, 0) ${ratio * 70}%)`
						}}
					/>
					<div
						className={styles.overlay}
						style={{
							background: `linear-gradient(0deg, rgba(${rgb1.r}, ${rgb1.g}, ${
								rgb1.b
							}, 1) 0%, rgba(${rgb1.r}, ${rgb1.g}, ${rgb1.b}, 0) ${ratio * 100}%)`
						}}
					/>
				</>
			)}
			{scheme === 'company' && (
				<>
					<div
						className={styles.overlay}
						style={{
							background: `linear-gradient(0deg, rgba(${rgb1.r}, ${rgb1.g}, ${
								rgb1.b
							}, 1) 0%, rgba(${rgb1.r}, ${rgb1.g}, ${rgb1.b}, 0) ${ratio * 100}%)`
						}}
					/>
					<div
						className={styles.overlay}
						style={{
							background: `linear-gradient(60.5deg, rgba(${rgb2.r}, ${rgb2.g}, ${
								rgb2.b
							}, 1) 0%, rgba(${rgb1.r}, ${rgb1.g}, ${rgb1.b}, 0) ${ratio * 70}%)`
						}}
					/>
				</>
			)}
		</div>
	)
}
