import { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { ComponentCompanyColor, ComponentModulesImage, Vendor } from 'etc/strapi-types'
import { getColors } from 'services/color'
import { Button, Grid, Typography } from 'antd'
import Spacer from 'components/spacer'
import styles from './image-module.module.less'

const { Text } = Typography
const { useBreakpoint } = Grid

interface Props {
	module: ComponentModulesImage
	vendor?: Vendor
	colors?: ComponentCompanyColor
}

const ImageModule = ({ module, vendor, colors }: Props) => {
	const container = useRef<HTMLDivElement>(null)
	const card = useRef<HTMLDivElement>(null)

	const [overflow, setOverflow] = useState(false)

	const { image, title, subtitle, text, link, color, margins } = module

	const { background, accent, content } = getColors(colors ?? vendor?.color ?? null, color?.scheme!)

	const screen = useBreakpoint()

	useEffect(() => {
		function onResize() {
			if (container.current && card.current) {
				if (container.current.clientHeight - 48 < card.current.clientHeight) {
					setOverflow(true)
				} else {
					setOverflow(false)
				}
			}
		}

		onResize()

		window.addEventListener('resize', onResize)

		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	return (
		<div>
			{margins?.top && <Spacer size={screen.md ? 78 : 48} />}
			<div ref={container} className={styles.container}>
				<img src={image?.url} alt='Bild' className={styles.image} />
				{(subtitle || title || text || link) && (
					<div
						ref={card}
						className={styles.card}
						style={{
							backgroundColor: background,
							display: overflow ? 'none' : undefined
						}}
					>
						{subtitle && (
							<Text className='semibold-16' style={{ color: accent }}>
								{subtitle}
							</Text>
						)}
						{title && (
							<div className={styles.title}>
								<Text className={`semibold-30 grey-${content === 'dark' ? 9 : 1}`}>{title}</Text>
							</div>
						)}
						{text && (
							<Text className={`regular-16 grey-${content === 'dark' ? 8 : 1}`}>{text}</Text>
						)}
						{link && (
							<div className={styles.button}>
								<Link href={link.href}>
									<Button type='primary' color={accent}>
										{link.name}
									</Button>
								</Link>
							</div>
						)}
					</div>
				)}
			</div>
			{overflow && (
				<div>
					<Spacer size={24} />
					{subtitle && (
						<Text className='semibold-16' style={{ color: accent }}>
							{subtitle}
						</Text>
					)}
					{title && (
						<div className={styles.title}>
							<Text className='semibold-30 grey-9'>{title}</Text>
						</div>
					)}
					{text && <Text className='regular-16 grey-8'>{text}</Text>}
					{link && (
						<div className={styles.button}>
							<Link href={link.href}>
								<Button type='primary' color={accent}>
									{link.name}
								</Button>
							</Link>
						</div>
					)}
				</div>
			)}
			{margins?.bottom && <Spacer size={screen.md ? 78 : 48} />}
		</div>
	)
}

export default ImageModule
