import { useEffect, useRef, useState } from 'react'
import {
	ComponentCompanyColor,
	ComponentModulesGallery,
	UploadFile,
	Vendor
} from 'etc/strapi-types'
import { Button, Col, Grid, Row, Typography } from 'antd'
import { getColors } from 'services/color'
import Spacer from 'components/spacer'
import Lightbox from './lightbox'
import styles from './gallery-module.module.less'

const { useBreakpoint } = Grid
const { Text } = Typography

interface ImgProps {
	src?: string
}

const Img = ({ src }: ImgProps) => {
	const ref = useRef<HTMLImageElement>(null)

	const [width, setWidth] = useState(300)

	useEffect(() => {
		function onResize() {
			if (ref.current) {
				setWidth(ref.current.clientWidth)
			}
		}

		onResize()

		window.addEventListener('resize', onResize)

		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [ref.current])

	return <img ref={ref} src={src} className={styles.tile} style={{ height: (width / 4) * 2.7 }} />
}

interface Props {
	module: ComponentModulesGallery
	vendor?: Vendor
	colors?: ComponentCompanyColor
}

const GalleryModule = ({ module, vendor, colors }: Props) => {
	const { images, title, subtitle, text, layout, imageAlignLeft, color, margins } = module

	const [lightboxVisible, setLightboxVisible] = useState(false)

	if (!images) return null

	const { background, accent, content } = getColors(
		colors ?? vendor?.color ?? null,
		layout === 'full' && images.length >= 8 ? color?.scheme! : 'light'
	)

	const screen = useBreakpoint()

	return (
		<div className={styles.container}>
			<Lightbox
				visible={lightboxVisible}
				hide={() => setLightboxVisible(false)}
				images={images as UploadFile[]}
			/>
			{margins?.top && <Spacer size={screen.md ? 78 : 48} />}
			{layout === 'full' && images.length >= 8 ? (
				<Row gutter={screen.lg ? [16, 16] : [12, 12]}>
					<Col span={8}>
						<Img src={images[0]?.url} />
					</Col>
					<Col span={8}>
						<Img src={images[1]?.url} />
					</Col>
					<Col span={8}>
						<Img src={images[2]?.url} />
					</Col>
					<Col span={8}>
						<Img src={images[3]?.url} />
					</Col>
					<Col span={0} md={8}>
						<div className={styles.mid} style={{ backgroundColor: background }}>
							{subtitle && (
								<Text className={`semibold-${screen.md ? 16 : 14}`} style={{ color: accent }}>
									{subtitle}
								</Text>
							)}
							{title && (
								<div className={styles.title}>
									<Text
										className={`semibold-${screen.lg ? 24 : 16} grey-${content === 'dark' ? 9 : 1}`}
									>
										{title}
									</Text>
								</div>
							)}
							<div>
								<Button
									onClick={() => setLightboxVisible(true)}
									color={accent}
									type='primary'
									className={styles.button}
								>
									Alle anzeigen
								</Button>
							</div>
						</div>
					</Col>
					<Col span={8}>
						<Img src={images[4]?.url} />
					</Col>
					<Col span={8}>
						<Img src={images[5]?.url} />
					</Col>
					<Col span={0} md={8}>
						<Img src={images[6]?.url} />
					</Col>
					<Col span={0} md={8}>
						<Img src={images[7]?.url} />
					</Col>
					<Col span={24} md={0}>
						<Spacer size={12} />
						{subtitle && (
							<Text className='semibold-16' style={{ color: accent }}>
								{subtitle}
							</Text>
						)}
						{title && (
							<div className={styles.title}>
								<Text className='semibold-20 grey-9'>{title}</Text>
							</div>
						)}
						<Button
							onClick={() => setLightboxVisible(true)}
							color={accent}
							type='primary'
							className={styles.button}
						>
							Alle anzeigen
						</Button>
					</Col>
				</Row>
			) : (
				<Row
					gutter={[40, 24]}
					justify='space-between'
					align='middle'
					style={imageAlignLeft ? undefined : { flexDirection: 'row-reverse' }}
				>
					<Col span={24} md={12}>
						{layout === 'tiles' && images.length >= 4 ? (
							<Row gutter={[12, 12]}>
								<Col span={12}>
									<Img src={images[0]?.url} />
								</Col>
								<Col span={12}>
									<Img src={images[1]?.url} />
								</Col>
								<Col span={12}>
									<Img src={images[2]?.url} />
								</Col>
								<Col span={12}>
									<Img src={images[3]?.url} />
								</Col>
							</Row>
						) : (
							<img src={images[0]?.url} alt='Bild' className={styles.image} />
						)}
					</Col>
					<Col span={24} md={12} lg={11}>
						{subtitle && (
							<Text className={`semibold-${screen.md ? 20 : 16}`} style={{ color: accent }}>
								{subtitle}
							</Text>
						)}
						{title && (
							<div className={styles.title}>
								<Text className='semibold-30 grey-9'>{title}</Text>
							</div>
						)}
						{text && (
							<div className={styles.text}>
								<Text className='regular-16 grey-8'>{text}</Text>
							</div>
						)}
						<Button
							onClick={() => setLightboxVisible(true)}
							color={accent}
							type='primary'
							className={styles.button}
						>
							Alle anzeigen
						</Button>
					</Col>
				</Row>
			)}
			{margins?.bottom && <Spacer size={screen.md ? 78 : 48} />}
		</div>
	)
}

export default GalleryModule
