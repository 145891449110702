import Link from 'next/link'
import { useQuery } from '@apollo/client'
import { COLLECTION_PRODUCTS } from 'etc/queries'
import { Collection } from 'etc/storefront-types'
import { ComponentModulesCatalog, Vendor } from 'etc/strapi-types'
import { Button, Col, Row, Typography } from 'antd'
import Spacer from 'components/spacer'
import Catalog from 'components/catalog/catalog'
import styles from './catalog-module.module.less'

const { Text } = Typography

interface Props {
	module: ComponentModulesCatalog
	vendor?: Vendor
}

const CatalogModule = ({ module, vendor }: Props) => {
	const { type, category, title, link, margins } = module

	const { data } = useQuery<{ collectionByHandle: Collection }>(COLLECTION_PRODUCTS, {
		variables: {
			collection: category ?? vendor?.slug,
			first: type === 'slider' ? 20 : type === 'grid' ? 8 : 250
		}
	})

	return (
		<div className={styles.container}>
			{margins?.top && <Spacer size={78} />}
			{(link || title) && (
				<div>
					<Row justify='space-between'>
						<Col>
							<Text className='semibold-30'>{title}</Text>
						</Col>
						{link && (
							<Col>
								<Link href={link?.href}>
									<Button>{link?.name}</Button>
								</Link>
							</Col>
						)}
					</Row>
					<Spacer size={48} />
				</div>
			)}
			<Catalog
				products={data?.collectionByHandle?.products}
				horizontal={type === 'slider'}
				filter={type === 'full'}
			/>
			{margins?.bottom && <Spacer size={78} />}
		</div>
	)
}

export default CatalogModule
