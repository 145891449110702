import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { ComponentModulesRichtext } from 'etc/strapi-types'
import MediaEmbed from './media-embed'
import Spacer from 'components/spacer'
import styles from './rich-text.module.less'

interface Props {
	module: ComponentModulesRichtext
}

const RichTextModule = ({ module }: Props) => {
	const { content, margins } = module

	const options: HTMLReactParserOptions = {
		replace: (node: any) => {
			if (node.name === 'figure' && node.children[0]?.name === 'oembed') {
				return (
					<div className={styles.embed}>
						<MediaEmbed url={node.children[0].attribs.url} />
					</div>
				)
			}

			return <>{domToReact([node])}</>
		}
	}

	return (
		<div className={styles.container}>
			{margins?.top && <Spacer size={96} />}
			<div className={styles.content}>{parse(content, options)}</div>
			{margins?.bottom && <Spacer size={96} />}
		</div>
	)
}

export default RichTextModule
